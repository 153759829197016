import { Card, FlexBox, Typography } from "@vp/swan";
import { useLogger, useSwanStyleKeys, useUserContext } from "@vp/ubik-context";

export interface Props {
  credit: number;
}

export const Fragment = ({ credit }: Props) => {
  const logger = useLogger();
  const { locale } = useUserContext();
  logger.log("Rendering fragment for a locale", locale);

  useSwanStyleKeys(["core"]);

  return (
    <FlexBox>
      <Card style={{ width: "100%" }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          Merchandise Credit
        </Typography>
        <Typography mt={1}>Merchandise Credit: {credit}</Typography>
      </Card>
    </FlexBox>
  );
};
